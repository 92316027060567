import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';

function App() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={'/pageone'} />} />
      <Route path="/pageone" exact component={PageOne} />
      <Route path="/pagetwo" exact component={PageTwo} />
      <Route path="/pagethree" exact component={PageThree} />
      <Route render={() => <Redirect to={'/'} />} />
    </Switch>
  );
}

export default App;
