import React from 'react';
import logo from './logo.svg';
import { Link } from 'react-router-dom';

function PageOne() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          You're now in <code>page #1</code>. Welcome!
        </p>
        <p>
          Go to <Link to="/pagetwo">Page Two</Link>
        </p>
        <p>
          Go to <Link to="/pagethree">Page Three (no indexing)</Link>
        </p>
      </header>
    </div>
  );
}

export default PageOne;
